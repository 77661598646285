import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AlertMessage } from "@vacasa/react-components-lib";
import "./TreeEditorAlerts.scss";

interface TreeEditorAlertsProps {
    type: string;
    message: string;
    setMessage?: Dispatch<SetStateAction<any>>;
    withTime? : boolean;
}
export const TreeEditorAlerts: React.FC<TreeEditorAlertsProps> = ({ type, message, setMessage = (value) =>{}, withTime = true }) => {
    const [alert, setAlert] = useState<boolean>(true);

    useEffect((): (() => void) => {
        setAlert(true);
        const timeId = setTimeout(() => {
            setAlert(false);
            setMessage(null);
        }, 3000);

        return () => {
            clearTimeout(timeId);
        };
    }, [message, setMessage]);

    return (
        <React.Fragment>
            {withTime && alert && (
                <div className="container-fluid" style={{padding: "0px"}} >
                    <div className="row">
                        <div className="col-sm-12" style={{marginBottom: "10px"}}>
                            <AlertMessage type={type as any} text={message} height="small" />
                        </div>
                    </div>
                </div>
            )}

            {!withTime && (
                <div className="container-fluid" style={{padding: "0px"}} >
                    <div className="row">
                        <div className="col-sm-12" style={{marginBottom: "10px"}}>
                            <AlertMessage type={type as any} text={message} height="small" />
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};
