import React, { useEffect, useState } from "react";
import { UserPreferencesService } from "../../services/user-preferences.service";
import { NodeItem } from "../../types/node";
import { Loading } from "../../components/Loading/Loading";
import { Header } from "../../components/Header/Header";
import * as _ from "lodash";
import {UserTreeTab} from "../../components/UserTreeTab/UserTreeTab";
import {UserTreeEditorOptions} from "../../components/UserTreeEditorOptions/UserTreeEditorOptions";
import {UserPreferencesTree} from "../../types/userPreferencesTree";
import {LoggingService} from "../../services/logging.service";
import {useHistory} from "react-router-dom";

interface UserTreeProps {}
export const UserTree: React.FC<UserTreeProps> = (props) => {
    const history = useHistory();
    if(!LoggingService.canReadUserPreferences()) {
        if(LoggingService.canReadCategories()) {
            history.push("/trees")
        }
        else {
            throw new Error("You don't have enough permissions to view this page.")
        }
    }

    const GUEST_TREE_CODE = "GUEST-TREE";
    const OWNER_TREE_CODE = "OWNER-TREE";
    const TREE_CODE_BY_TABS = [GUEST_TREE_CODE, OWNER_TREE_CODE];

    //FLAGS
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isModeEdition, setModeEdition] = useState<boolean>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [successfulSaving, setSuccessfulSaving] = useState<boolean>(false);

    //ERRORS
    const [error, setError] = useState<string>(null);

    //DATA
    let propertiesChanges = {};
    const [userEmail, setUserEmail] = useState<string>("");
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [tree, setTree] = useState<any>({
        tree_code: "",
        description: "",
        schema: [
            {
                key: "name_variable",
                type: "boolean",
                allow_null: false
            }
        ],
        send_to_sf: false
    });

    //GET TREE
    const loadTree = async (email?: string): Promise<void> => {
        if(!LoggingService.canReadUserPreferences()) {
            setError("You don't have permissions for this");
            return;
        }

        try {
            propertiesChanges = {};
            email = email?.trim() ?? userEmail;
            if (email) {
                setIsLoading(true);
                setError(null);
                const userTreeInfo = await UserPreferencesService.getUserTreePreferences(TREE_CODE_BY_TABS[currentTab], email);
                setUserEmail(email);

                const selectedTab = userTreeInfo.isOwner ? 1 : 0;
                if(currentTab === selectedTab) {
                    await updateNodes(userTreeInfo);
                }
                else {
                    setCurrentTab(userTreeInfo.isOwner ? 1 : 0);
                }
            }

        } catch (e) {
            const errorMessage = _.get(e.response, "data.[0].detail.error.message") ?? e.toString();
            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const refreshTree = async (): Promise<void> => {
        try {
            propertiesChanges = {};
            setIsLoading(true);
            setError(null);
            const userTreeInfo = await UserPreferencesService.getUserTreePreferences(TREE_CODE_BY_TABS[currentTab], userEmail);
            await updateNodes(userTreeInfo);
        } catch (e) {
            const errorMessage = _.get(e.response, "data.[0].detail.error.message") ?? e.toString();
            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadTree();
    }, [currentTab])

    const transformTreeInObject = (nodes: { [key: string]: NodeItem }, root_id: string) => {
        const currentNode = nodes[root_id];
        const childrenList = [];
        for (const child of currentNode.children) {
            childrenList.push(transformTreeInObject(nodes, child.toString()));
        }
        return { ...currentNode, childrenList };
    };

    const updateNodes = async (treeInfo: Partial<UserPreferencesTree>): Promise<void> => {
        setTree({ ...treeInfo, objectChildren: transformTreeInObject(treeInfo.nodes as any, treeInfo.root_id?.toString()) });
    };

    const addChange = (node_id: string, key: string, value: any) => {
        if(!propertiesChanges[node_id]) {
            propertiesChanges[node_id] = {};
        }
        propertiesChanges[node_id][key] = value;
    };

    const saveChanges = async () => {
        setIsSaving(true);
        try{
            const treeCode = TREE_CODE_BY_TABS[currentTab];
            for(const nodeID in propertiesChanges){
                for(const key in propertiesChanges[nodeID]){
                    await UserPreferencesService.saveUserPreference(tree.idpUserId, treeCode, {
                        node_id: +nodeID,
                        attributes: {
                            key: key,
                            value: propertiesChanges[nodeID][key]
                        }
                    });
                }
            }
            setIsSaving(false);
            setSuccessfulSaving(true);
            await loadTree();
        }
        catch(e) {
            const errorMessage = JSON.stringify(_.get(e.response, "data.[0]")) ?? e.toString();
            setError(errorMessage);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="container-fluid font-custom">
            <div className="row">
                {isLoading ? (
                    <div className="col-sm-12">
                        <Loading className="padding" />
                    </div>
                ) : (
                    <div className="col-sm-12">
                        <Header>
                            <UserTreeEditorOptions
                                title={"User preferences"}
                                isSavingData={isSaving}
                                isModeEdition={isModeEdition}
                                setModeEdition={setModeEdition}
                                email={userEmail}
                                loadTree={loadTree}
                                saveChanges={saveChanges}
                            />
                        </Header>

                        <UserTreeTab
                            tree={tree}
                            isSavingData={isSaving}
                            selectedTab={currentTab}
                            isModeEdition={isModeEdition}
                            addChange={addChange}
                            successfulOperation={successfulSaving}
                            setSuccessfulOperation={setSuccessfulSaving}
                            error={error}
                            setError={setError}
                            refreshTree={refreshTree}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
