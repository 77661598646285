export enum SCOPES {
    CATEGORIES_READ = "ups:read-categories",
    CATEGORIES_WRITE = "ups:write-categories",
    PREFERENCES_READ = "ups-user-tree:read",
    PREFERENCES_WRITE = "ups-user-tree:write"
}

export const ALL_SCOPES = [SCOPES.CATEGORIES_READ, SCOPES.CATEGORIES_WRITE, SCOPES.PREFERENCES_READ, SCOPES.PREFERENCES_WRITE];

export interface IdentityToken {
    sub: string;
    name: string;
    given_name: string;
    family_name: string;
    email: string;
    iss: string;
    nonce: string;
}

export interface AccessToken {
    aud: string;
    client_id: string;
    exp: number;
    iat: number;
    iss: string;
    jti: string;
    scopes: string[];
    sub: string;
}