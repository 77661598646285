import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Modal } from "@vacasa/react-components-lib";
import { UserPreferencesService } from "../../services/user-preferences.service";
import { Tree } from "../../types/trees";
import * as _ from "lodash";
import { Confirm } from "../Confirm/Confirm";
import { DRAFT } from "../../types/constants";
import "./DeleteNodeButton.scss";

interface DeleteNodeProps {
    nodeId: number;
    tree: Tree;
    updateNodes: (tree: Tree) => void;
    setError: Dispatch<SetStateAction<string>>;
    setNodeSaved: Dispatch<SetStateAction<boolean>>;
    setNodeId: Dispatch<SetStateAction<number>>;
    setMessageOperation: Dispatch<SetStateAction<string>>;
    isModeEdition: boolean;
}
export const DeleteNodeButton: React.FC<DeleteNodeProps> = ({
    tree,
    updateNodes,
    nodeId,
    setError,
    setNodeSaved,
    setNodeId,
    setMessageOperation,
    isModeEdition
}) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const node = tree.nodes_list[nodeId];

    const deleteNodeConfirmation = async (): Promise<void> => {
        setShowConfirmationModal(true);
    };

    const defineStateAndValidation = () => {
        setMessageOperation("delete");
        setShowConfirmationModal(false);
        setIsDeleting(true);
        setNodeSaved(false);
        setError(null);
        if (node.is_first_node) {
            throw new Error("Root node cannot be deleted.");
        }
    };
    const deleteNode = async (): Promise<void> => {
        try {
            defineStateAndValidation();
            await UserPreferencesService.deleteNode(tree.tree_code, node.id);
            const updatedTree = await UserPreferencesService.getTree(tree.tree_code, { "filter[status]": DRAFT });
            updateNodes(updatedTree as any);
            setNodeId(null);
            setNodeSaved(true);
        } catch (e) {
            const errorMessage = _.get(e.response, "data.[0].detail.error.message") ?? e.toString();
            setError(errorMessage);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <React.Fragment>
            <Button variant="info" type="button" onClick={deleteNodeConfirmation} disabled={isDeleting || !isModeEdition}>
                {isDeleting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Delete"}
            </Button>

            <Modal showModal={showConfirmationModal} setShowModal={setShowConfirmationModal} size="medium">
                {
                    <Confirm
                        title={"Delete Node"}
                        message={`Are you sure you want to delete ${node?.display_name} Node?`}
                        showModal={showConfirmationModal}
                        setShowModal={setShowConfirmationModal}
                        confirmAction={deleteNode}
                    />
                }
            </Modal>
        </React.Fragment>
    );
};
