import React, { ReactNode } from "react";
import "./Header.scss";

interface HeaderProps {
    children: ReactNode;
}
export const Header: React.FC<HeaderProps> = ({ children }) => {
    return (
        <div className="container-fluid header without-padding">
            <div className="row">
                <div className="col-sm-12">{children}</div>
            </div>
        </div>
    );
};
