import React, { Dispatch, SetStateAction } from "react";
import "./FloatingPanel.scss";
import { Tree } from "../../types/trees";
import { DeleteNodeButton } from "../DeleteNodeButton/DeleteNodeButton";
import { SaveNodeButton } from "../SaveNodeButton/SaveNodeButton";
import { NodeItem } from "../../types/node";
import { SchemaProperty } from "../../types/schemaProperty";

interface FloatingPanelFooterProps {
    tree: Tree;
    nodeId?: number;
    setNodeId: Dispatch<SetStateAction<number>>;
    updateNodes: (tree: Tree) => void;
    setError: Dispatch<SetStateAction<string>>;
    setNodeSaved: Dispatch<SetStateAction<boolean>>;
    currentNode: NodeItem;
    schema: SchemaProperty[];
    externalServices: string[];
    setMessageOperation: Dispatch<SetStateAction<string>>;
    isModeEdition: boolean;
}
export const FloatingPanelFooter: React.FC<FloatingPanelFooterProps> = ({
    tree,
    nodeId,
    updateNodes,
    setError,
    setNodeSaved,
    currentNode,
    schema,
    setNodeId,
    externalServices,
    setMessageOperation,
    isModeEdition
}) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div className="footer">
                        {nodeId && (
                            <DeleteNodeButton
                                nodeId={nodeId}
                                tree={tree}
                                updateNodes={updateNodes}
                                setError={setError}
                                setNodeSaved={setNodeSaved}
                                setNodeId={setNodeId}
                                setMessageOperation={setMessageOperation}
                                isModeEdition={isModeEdition}
                            />
                        )}
                        <SaveNodeButton
                            nodeId={nodeId}
                            tree={tree}
                            updateNodes={updateNodes}
                            setError={setError}
                            setNodeSaved={setNodeSaved}
                            currentNode={currentNode}
                            schema={schema}
                            setNodeId={setNodeId}
                            externalServices={externalServices}
                            setMessageOperation={setMessageOperation}
                            isModeEdition={isModeEdition}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
