import React, { Dispatch, SetStateAction, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Page } from "../Page/Page";
import { TreeEditorAlerts } from "../TreeEditorAlerts/TreeEditorAlerts";
import "./UserTreeTab.scss";
import {UserTreeNodes} from "../TreeNodes/UserTreeNodes";
import {UserPreferencesTree} from "../../types/userPreferencesTree";

interface UserTabProps {
    tree: UserPreferencesTree;
    selectedTab: number;
    isModeEdition: boolean;
    isSavingData: boolean;
    addChange: (node_id: string, key: string, value: any) => void;
    successfulOperation: boolean;
    setSuccessfulOperation: Dispatch<SetStateAction<boolean>>;
    error: string;
    setError: Dispatch<SetStateAction<string>>;
    refreshTree: () => Promise<void>;
}
export const UserTreeTab: React.FC<UserTabProps> = ({
    tree,
    selectedTab,
    isModeEdition,
    isSavingData,
    addChange,
    successfulOperation,
    setSuccessfulOperation,
    error,
    setError,
    refreshTree
}) => {
    useEffect(() => {
        if (tree.nodes) {
            const categories = [];

            for (const [, value] of Object.entries(tree.nodes)) {
                if (value?.children?.length === 0) {
                    categories.push(value);
                }
            }
        }
    }, [tree]);

    const a11yProps = (index: number): { id: string; "aria-controls": string } => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`
        };
    };

    return (
        <React.Fragment>
            <Box sx={{ width: "100%", fontFamily: "Nunito Sans" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={selectedTab}
                        onChange={(event: React.SyntheticEvent, newValue: number) => {}}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{ style: { background: "#99adb6" } }}
                        sx={{ fontFamily: "Nunito Sans" }}
                    >
                        <Tab
                            label="Guest"
                            className={"disabled"}
                            {...a11yProps(0)}
                            disabled={true}
                        />
                        <Tab
                            label="Owner"
                            className={"disabled"}
                            {...a11yProps(1)}
                            disabled={true}
                        />
                    </Tabs>
                </Box>
                <Box sx={{ border: 0, borderColor: "divider", marginTop: "10px"}}>
                    <div>
                        {error && <TreeEditorAlerts type="error" message={error} setMessage={setError}/>}
                        {successfulOperation && (
                            <TreeEditorAlerts type="success" message={`Preferences saved!`} setMessage={setSuccessfulOperation} />
                        )}
                    </div>

                    <div className={"d-flex"}>
                        <div className={isModeEdition ? "col-6 box-left" : "col-12"}>
                            <Page title={isModeEdition ? "Current configuration" : null}>
                                <div className="container">
                                    <div className="row">
                                        <div className={"col-lg-12"}>
                                            <div className="container">
                                                <div className="row">
                                                    <div className={"col-lg-11"}>
                                                        { tree.tree_code !== '' && (
                                                            <UserTreeNodes
                                                                tree={tree}
                                                                isSavingData={isSavingData}
                                                                isTreeEditable={false}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Page>
                        </div>

                        {isModeEdition && (
                            <div className={"col-6 box-right"}>
                                <Page title={"New configuration"} refreshAction={refreshTree}>
                                    <div className="container">
                                        <div className="row">
                                            <div className={"col-lg-12"}>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className={"col-lg-11"}>
                                                            { tree.tree_code !== '' && (
                                                                <UserTreeNodes
                                                                    tree={tree}
                                                                    isSavingData={isSavingData}
                                                                    isTreeEditable={true}
                                                                    addChange={addChange}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Page>
                            </div>
                        )}
                    </div>
                </Box>
            </Box>
        </React.Fragment>
    );
};
