import React, { useEffect, useState } from "react";
import "./Table.scss";
import { Tree } from "../../types/trees";
import MaterialTable from "material-table";
import { Icon } from "@vacasa/react-components-lib";
import { useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { Pill } from "../Pill/Pill";


interface TableProps {
    data: Partial<Tree>[];
    cloneTree: (treeCode: string) => void;
    deleteTree: (treeCode: string, status: string) => void;
}
export const Table: React.FC<TableProps> = ({ data, deleteTree, cloneTree }) => {
    const history = useHistory();
    const rowsLocalStorage = Number(localStorage.getItem("pageSize"));

    const rows = rowsLocalStorage > 0 ? rowsLocalStorage : 10;
    const [pageSize, setPageSize] = useState<number>(rows);

    useEffect((): void => {
        localStorage.setItem("pageSize", pageSize.toString());
    }, [pageSize]);

    return (
        <MaterialTable
            data={data}
            columns={[
                { title: "Id", field: "id" },
                { title: "Code", field: "tree_code" },
                { title: "Updated by", field: "updated_by" },
                { title: "Last Update", field: "updated_at" },
                { title: "Status", field: "status", render: (row) => <Pill type={row.status} /> }
            ]}
            actions={[
                (rowData) => ({
                    icon: () =>
                        rowData.isLive ? (
                            <Icon.Copy height={20} width={20} className={rowData.classNameEdit} />
                        ) : (
                            <Icon.Edit3 height={20} width={20} />
                        ),
                    tooltip: `${rowData.tooltipEdit}`,
                    onClick: (event, row) => (rowData.isLive ? cloneTree(row["tree_code"]) : history.push(`/edit/${row["tree_code"]}`)),
                    disabled: rowData.hasDraft
                }),
                {
                    icon: () => <Icon.Eye height={20} width={20} />,
                    tooltip: "Preview Tree",
                    onClick: (event, row) => {
                        history.push(`/view/${row["tree_code"]}/${row["status"]?.toLowerCase()}`);
                    }
                },
                {
                    icon: () => <Icon.Trash2 height={20} width={20} />,
                    tooltip: "Delete Tree",
                    onClick: (event, row) => deleteTree(row["tree_code"], row["status"])
                }
            ]}
            options={{
                actionsColumnIndex: -1,
                showTitle: false,
                search: false,
                toolbar: false,
                pageSize: pageSize,
                headerStyle: {
                    borderBottom: "solid 2px #99adb6",
                    fontFamily: "Nunito sans",
                    fontWeight: "bold",
                    color: "#707579"
                },
                rowStyle: {
                    fontFamily: "Nunito sans",
                    color: "#707579"
                }
            }}
            components={{
                Container: (props) => <Paper {...props} elevation={0} />
            }}
            onChangeRowsPerPage={(event) => {
                setPageSize(event);
            }}
        />
    );
};
