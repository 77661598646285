import React, { Dispatch, SetStateAction, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Page } from "../Page/Page";
import { TreeEditor } from "../TreeEditor/TreeEditor";
import { TabPanel } from "./TabPanel";
import { TreeNodes } from "../TreeNodes/TreeNodes";
import { TreeEditorAlerts } from "../TreeEditorAlerts/TreeEditorAlerts";
import { Tree } from "../../types/trees";
import "./TreeTab.scss";
import { FloatingPanel } from "../FloatingPanel/FloatingPanel";
import { Icon } from "@vacasa/react-components-lib";
import * as _ from "lodash";
import { CATEGORY } from "../../types/constants";

interface TabProps {
    treeCode: string;
    isModeEdition: boolean;
    treeSaved: boolean;
    tree: Tree;
    error: string;
    setTree: Dispatch<SetStateAction<Tree>>;
    setCurrentTab: Dispatch<SetStateAction<number>>;
    updateNodes: (tree: Tree) => void;
    setError: Dispatch<SetStateAction<string>>;
    setIsSaved: Dispatch<SetStateAction<boolean>>;
    externalServiceList: Array<string>;
    savedLocalChangesNodes: boolean;
    setSavedLocalChangesNodes: Dispatch<SetStateAction<boolean>>;
    savedLocalChangesTree: boolean;
    setSavedLocalChangesTree: Dispatch<SetStateAction<boolean>>;
}
export const TreeTab: React.FC<TabProps> = ({
    treeCode,
    tree,
    error,
    treeSaved,
    setTree,
    setCurrentTab,
    isModeEdition,
    updateNodes,
    setError,
    setIsSaved,
    externalServiceList,
    savedLocalChangesNodes,
    savedLocalChangesTree,
    setSavedLocalChangesNodes,
    setSavedLocalChangesTree
}) => {
    const [selectedTab, setSelectedTab] = React.useState<number>(0);
    const [nodeId, setNodeId] = React.useState<number>(null);
    const [successfulOperation, setSuccessfulOperation] = React.useState<boolean>(false);
    const [messageOperation, setMessageOperation] = React.useState<string>(null);
    const [openNewPanel, setOpenNewPanel] = React.useState<boolean>(false);
    const [numberBadNodes, setNumberBadNodes] = React.useState<number>(0);

    const getValue = (key: string, defaults: Array<any>): any => {
        return defaults?.find((item) => item.key === key)?.value;
    };

    useEffect(() => {
        if (tree.nodes_list) {
            let count = 0;
            const categories = [];

            for (const [, value] of Object.entries(tree.nodes_list)) {
                if (value?.type === CATEGORY) {
                    categories.push(value);
                }
            }

            for (const node of categories) {
                let schemaLocal = [
                    ...tree.schema.map((item) => {
                        const value = getValue(item.key, node.defaults);
                        return { ...item, value, isOrphan: _.isUndefined(value) };
                    }),
                    ..._.differenceBy(node.defaults, tree.schema, "key").map((item) => ({ ...item, isOrphan: true }))
                ];

                const nodeWithOrphanNodes = schemaLocal?.find((item) => item.isOrphan);

                if (nodeWithOrphanNodes) {
                    count = count + 1;
                }
            }

            setNumberBadNodes(count);
        }
    }, [tree]);

    const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
        setSelectedTab(newValue);
        setCurrentTab(newValue);
    };

    const a11yProps = (index: number): { id: string; "aria-controls": string } => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`
        };
    };

    return (
        <React.Fragment>
            <Box sx={{ width: "100%", fontFamily: "Nunito Sans" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={selectedTab}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{ style: { background: "#99adb6" } }}
                        sx={{ fontFamily: "Nunito Sans" }}
                    >
                        <Tab
                            label="Main Configuration"
                            {...a11yProps(0)}
                            style={{ textTransform: "none", color: "#003349", fontFamily: "Nunito Sans" }}
                        />
                        <Tab
                            label="Nodes"
                            {...a11yProps(1)}
                            disabled={!treeCode || !savedLocalChangesTree}
                            style={{ textTransform: "none", color: "#003349", fontFamily: "Nunito Sans" }}
                        />
                    </Tabs>
                </Box>
                <TabPanel value={selectedTab} index={0}>
                    <div>
                        {!savedLocalChangesTree && <TreeEditorAlerts type="warning" message="Node not saved" withTime={false} />}
                        {error && <TreeEditorAlerts type="error" message={error} setMessage={setError} />}
                        {treeSaved && <TreeEditorAlerts type="success" message="Tree saved!" setMessage={setIsSaved} />}
                    </div>
                    <Page>
                        <TreeEditor
                            tree={tree}
                            isNew={!treeCode}
                            isModeEdition={isModeEdition}
                            setTree={setTree}
                            setSavedLocalChangesTree={setSavedLocalChangesTree}
                        />
                    </Page>
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <div>
                        {error && <TreeEditorAlerts type="error" message={error} setMessage={setError} />}
                        {successfulOperation && (
                            <TreeEditorAlerts type="success" message={`Node ${messageOperation}d!`} setMessage={setSuccessfulOperation} />
                        )}
                        {!savedLocalChangesNodes && numberBadNodes === 0 && (
                            <TreeEditorAlerts type="warning" message="Node not saved" withTime={false} />
                        )}
                        {!successfulOperation && numberBadNodes > 0 && (
                            <TreeEditorAlerts
                                type="error"
                                message={`Conflict between schema and default values. Affected nodes ${numberBadNodes}. Save the affected categories.`}
                                withTime={false}
                            />
                        )}
                    </div>
                    <Page>
                        <div className="container">
                            <div className="row">
                                <div className={nodeId || openNewPanel ? "col-lg-6 remove-padding-right" : "col-lg-12"}>
                                    <div className="container">
                                        <div className="row">
                                            <div className={nodeId || openNewPanel ? "col-lg-12" : "col-lg-11"}>
                                                <TreeNodes tree={tree} setNode={setNodeId} savedLocalChanges={savedLocalChangesNodes} />
                                            </div>
                                            {!nodeId && !openNewPanel && isModeEdition && (
                                                <div
                                                    className="col-lg-1"
                                                    style={{ textAlign: "right", cursor: "pointer", paddingTop: "10px", paddingRight: "25px" }}
                                                >
                                                    <Icon.PlusCircle height={20} width={20} onClick={() => setOpenNewPanel(true)} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {(nodeId || openNewPanel) && (
                                    <div className="col-lg-6 remove-padding-left">
                                        <FloatingPanel
                                            tree={tree}
                                            nodeId={nodeId}
                                            updateNodes={updateNodes}
                                            setError={setError}
                                            setNodeSaved={setSuccessfulOperation}
                                            setOpenNew={setOpenNewPanel}
                                            setNodeId={setNodeId}
                                            openNew={openNewPanel}
                                            externalServiceList={externalServiceList}
                                            setMessageOperation={setMessageOperation}
                                            setSavedLocalChanges={setSavedLocalChangesNodes}
                                            isModeEdition={isModeEdition}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Page>
                </TabPanel>
            </Box>
        </React.Fragment>
    );
};
