export enum StorageKeys {
    ACCESS_TOKEN = "accessToken",
    ID_TOKEN = "idToken"
}

export const LIVE = "live";
export const DRAFT = "draft";
export const CATEGORY = "category";
export const ROOT = "root";
export const GROUP = "group";

export const SELECT_STYLE = {
    fieldset: {
        "&.MuiOutlinedInput-notchedOutline": {
            border: "solid 1px #b2c2c8 !important"
        }
    }
};

export const TEXTFIELD_STYLE = {
    "& .MuiOutlinedInput-root": {
        "& > fieldset": {
            borderColor: "#b2c2c8"
        }
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
        "& > fieldset": {
            borderColor: "#b2c2c8"
        }
    },

    "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
            borderColor: "#b2c2c8"
        }
    }
};
