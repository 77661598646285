import React from "react";
import Chip from "@material-ui/core/Chip";
import "./Pill.scss";

interface PillProps {
    type: string;
}
export const Pill: React.FC<PillProps> = ({ type }) => {
    return <Chip label={type} className={type} />;
};
