import React from "react";
import { Icon } from "@vacasa/react-components-lib";
import { LoggingService } from "../../services/logging.service";
import logo from "../../assets/vacasa-iso.svg";
import "./Navbar.scss";
import { useHistory } from "react-router-dom";

interface NavbarProps {
    title: string;
    version: string;
}
export const Navbar: React.FC<NavbarProps> = ({ title, version }) => {
    const user = LoggingService.getUserName();
    const history = useHistory();
    const canReadCategories = LoggingService.canReadCategories();
    const canReadUserPreferences = LoggingService.canReadUserPreferences();

    return (
        <nav className="navbar navbar-expand navbar-vacasa">
            <div className="container-fluid">
                <span className="navbar-brand mb-0" onClick={() => history.push("/trees")}>
                    <img src={logo} width="33" height="31" className="d-inline-block align-top" alt="" />
                    <span className="navbar-vacasa-logo">{title}</span>
                    <span className="navbar-vacasa-logo-info">{version}</span>
                </span>
                <ul className="navbar-nav box">
                    {canReadCategories && canReadUserPreferences && (
                        <React.Fragment>
                            <li className="nav-item">
                                <span className="nav-link navbar-vacasa-nav-link" onClick={() => history.push("/trees")}>Global trees</span>
                            </li>
                            <li className="nav-item">
                                <span className="nav-link navbar-vacasa-nav-link" onClick={() => history.push("/user-trees")}>User trees</span>
                            </li>
                        </React.Fragment>
                    )}
                    <li className="nav-item dropdown">
                        <span
                            className="nav-link dropdown-toggle navbar-vacasa-dropdown"
                            id="navbarDropdownMenuLink"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <Icon.User className="navbar-vacasa-icon navbar-vacasa-icon-color" height={20} width={20} />
                            <span className="navbar-vacasa-dropdown-text">{user}</span>
                        </span>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                            <li>
                                <span className="dropdown-item">Logout</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    );
};
