import React from "react";
import { Button, Icon } from "@vacasa/react-components-lib";
import { useHistory } from "react-router-dom";
import "./TreesOptions.scss";

interface TreesOptionsProps {}
export const TreesOptions: React.FC<TreesOptionsProps> = (props) => {
    const history = useHistory();
    return (
        <div className="container-fluid without-padding">
            <div className="row">
                <div className="col-sm-6 ups-title">Global Trees</div>
                <div className="col-sm-6">
                    <div className="float-right">
                        <Button variant="secondary" onClick={() => history.push("/new")}>
                            <Icon.Plus height={20} width={20} /> New
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
