import React from "react";
import Box from "@mui/material/Box";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import { styled } from "@mui/material/styles";
import CropFree from "@mui/icons-material/CropFree";
import LayersOutlined from "@mui/icons-material/LayersOutlined";
import { CATEGORY } from "../../types/constants";
import "./TreeNodes.scss";
declare module "react" {
    interface CSSProperties {
        "--tree-node-background"?: string;
        "--tree-node-radius"?: string;
        "--tree-margin-left"?: string;
    }
}

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => {
    return {
        color: theme.palette.text.secondary,
        width: "400px",
        [`& .${treeItemClasses.content}`]: {
            flexDirection: "row-reverse",
            margin: "10px 0px 0px 0px",
            background: `var(--tree-node-background, ${theme.palette.action.selected})`,
            color: "white",
            borderRadius: "var(--tree-node-radius)",
            "&:hover": {
                background: `var(--tree-node-background, ${theme.palette.action.selected})`
            },
            "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
                background: `var(--tree-node-background, ${theme.palette.action.selected})`
            },
            "&.Mui-selected:hover": {
                background: `var(--tree-node-background)`
            }
        },
        [`& .${treeItemClasses.group}`]: {
            marginLeft: "var(--tree-margin-left)",
            paddingLeft: "var(--tree-margin-left)",
            [`& .${treeItemClasses.content}`]: {
                background: `var(--tree-node-background, ${theme.palette.action.selected})`
            }
        }
    };
});

type StyledTreeItemProps = TreeItemProps & {
    title: string;
    typeNode: string;
    corruptCategory?: boolean;
};
export const Node: React.FC<StyledTreeItemProps> = ({ title, typeNode, corruptCategory, ...other }) => {
    let color = "#0078AB";
    let marginLeftCategory = "20px";
    let radius = "10px";
    let icon = LayersOutlined;

    if (typeNode === CATEGORY) {
        color = "#6BA342";
        radius = "10px 10px 0px 0px";
        icon = CropFree;
        marginLeftCategory = "0px";
    }

    if (corruptCategory) {
        color = "#d62e4f";
    }

    return (
        <StyledTreeItemRoot
            key={other.nodeId}
            label={
                <Box sx={{ display: "flex", alignItems: "center", p: 1.5, pr: 0 }}>
                    <Box component={icon} color="inherit" sx={{ mr: 1 }} />
                    <div style={{ paddingLeft: 20 }}>{title} (ID: {other.nodeId})</div>
                </Box>
            }
            style={{
                "--tree-node-background": color,
                "--tree-node-radius": radius,
                "--tree-margin-left": marginLeftCategory
            }}
            {...other}
        />
    );
};
