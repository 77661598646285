import React, {Dispatch, SetStateAction, useState} from "react";
import {Button, Modal} from "@vacasa/react-components-lib";
import "./UserTreeEditorOptions.scss";
import {Confirm} from "../Confirm/Confirm";
import {LoggingService} from "../../services/logging.service";
import {Input} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

interface UserTreeEditorOptionsProps {
    title: string;
    email:string;
    isModeEdition: boolean;
    setModeEdition: Dispatch<SetStateAction<boolean>>;
    loadTree: (email: string) => Promise<void>;
    isSavingData: boolean;
    saveChanges: () => Promise<void>;
}
export const UserTreeEditorOptions: React.FC<UserTreeEditorOptionsProps> = ({
    title,
    email,
    isModeEdition,
    setModeEdition,
    isSavingData,
    loadTree,
    saveChanges
}) => {
    const [userTempEmail, setUserTempEmail] = useState<string>(email);
    const [isCancelModalVisible, setCancelModalVisible] = useState<boolean>(false);
    const [isUpdateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
    const isAllowedToWrite = LoggingService.canWriteUserPreferences();

    const getEditUpdateButton = (label: string) => {
        if(!isSavingData) {
            return (<React.Fragment>{label}</React.Fragment>)
        }
        return (
            <React.Fragment>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </React.Fragment>
        )
    };

    const discardChanges = async () => {
        setModeEdition(false);
        setCancelModalVisible(false);
    };

    const savePreferencesChanges = async () => {
        setUpdateModalVisible(false);
        await saveChanges();
        setModeEdition(false);
    }

    return (
        <div className="container-fluid without-padding">
            <div className="row">
                <div className="col-sm-4 ups-title">{title}</div>
                <div className="col-sm-8">
                    <div className="float-right">
                        <label htmlFor={"userEmail"} className="col-lg-3 col-form-label">User search:</label>
                        <form onSubmit={(event) => {
                                event.preventDefault();
                                loadTree(userTempEmail);
                            }}
                        >
                            <Input
                                id={"userEmail"}
                                type="text"
                                className={"form-control padding-left"}
                                value={userTempEmail}
                                placeholder={"Write the user email"}
                                onChange={(event) => {
                                    setUserTempEmail(event.target.value);
                                }}
                                disabled={isModeEdition || isSavingData}
                                disableUnderline={true}
                                endAdornment={(<SearchIcon className={"search-icon"} onClick={(event) => {
                                    event.preventDefault();
                                    loadTree(userTempEmail);
                                }} />)}
                            ></Input>
                        </form>
                        <Button
                            variant="info"
                            type="button"
                            customClass="padding-left user-tree-button"
                            onClick={() => setCancelModalVisible(true)}
                            disabled={!email || !isModeEdition || isSavingData}
                        >
                            {"Cancel"}
                        </Button>
                        {!isModeEdition && (
                            <React.Fragment>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    customClass="padding-left user-tree-button"
                                    onClick={() => setModeEdition(true)}
                                    disabled={!email || isSavingData || !isAllowedToWrite}
                                >
                                    {getEditUpdateButton("Edit")}
                                </Button>
                            </React.Fragment>
                        )}
                        {isModeEdition && (
                            <React.Fragment>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    customClass="padding-left user-tree-button"
                                    onClick={() => setUpdateModalVisible(true)}
                                    disabled={!email || isSavingData}
                                >
                                    {getEditUpdateButton("Update")}
                                </Button>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
            <Modal showModal={isCancelModalVisible} setShowModal={setCancelModalVisible} size="medium">
                {
                    <Confirm
                        title={"Discard changes"}
                        message={`Are you sure you want to discard all changes?`}
                        showModal={isCancelModalVisible}
                        setShowModal={setCancelModalVisible}
                        confirmAction={discardChanges}
                    />
                }
            </Modal>
            <Modal showModal={isUpdateModalVisible} setShowModal={setUpdateModalVisible} size="medium">
                {
                    <Confirm
                        title={"Save changes"}
                        message={`Some settings have been changed for ${email}. Would you like to proceed and save them?`}
                        showModal={isUpdateModalVisible}
                        setShowModal={setUpdateModalVisible}
                        confirmAction={savePreferencesChanges}
                    />
                }
            </Modal>
        </div>
    );
};
