import React, { Dispatch, SetStateAction } from "react";
import { Icon, Button } from "@vacasa/react-components-lib";
import "./ConfirmError.scss";

interface ModalProps {
    title: string;
    message: string;
    errors: string | string[];
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    confirmAction?: () => Promise<void>;
}
export const ConfirmError: React.FC<ModalProps> = ({ title, message, errors, setShowModal, showModal, confirmAction }) => {
    return (
        <div>
            <div className="confirmation-title">
                <b>{title}</b>
            </div>
            <hr></hr>
            <div className="modal-body">
                <Icon.AlertTriangle height={50} width={50} className="modal-alert-icon" />
                <b style={{ marginBottom: "10px", textAlign: "center", display: "block" }}>{message}</b>
                {typeof errors == "string" && (
                    <React.Fragment>
                        <p>-{errors}</p>
                    </React.Fragment>
                )}
                {typeof errors == "object" &&
                    errors.map((text, index) => (
                        <React.Fragment>
                            <p style={{ marginBottom: "0" }}>-{text}</p>
                        </React.Fragment>
                    ))}
                <div className="button-div">
                    <Button variant="secondary" onClick={() => setShowModal(!showModal)}>
                        Confirm
                    </Button>
                </div>
            </div>
        </div>
    );
};
