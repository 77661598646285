import React from "react";
import { Button } from "@vacasa/react-components-lib";
import { useHistory } from "react-router-dom";
import "./TreeEditorOptions.scss";

interface TreeEditorOptionsProps {
    title: string;
    isSavingData: boolean;
    isPublishing: boolean;
    treeCode: string;
    isFirstTab: boolean;
    isModeEdition: boolean;
    isDraft: boolean;
    disabledPublish: boolean;
    saveTree: () => Promise<void>;
    publishTree: () => Promise<void>;
}
export const TreeEditorOptions: React.FC<TreeEditorOptionsProps> = ({
    title,
    isSavingData,
    isPublishing,
    treeCode,
    isFirstTab,
    isModeEdition,
    isDraft,
    saveTree,
    publishTree,
    disabledPublish
}) => {
    const history = useHistory();
    return (
        <div className="container-fluid without-padding">
            <div className="row">
                <div className="col-sm-4 ups-title">{title}</div>
                <div className="col-sm-8">
                    <div className="float-right">
                        <Button
                            variant="info"
                            type="button"
                            customClass="padding-left"
                            onClick={() => history.push("/trees")}
                            disabled={isPublishing || isSavingData}
                        >
                            {isModeEdition ? "Cancel" : "Return"}
                        </Button>
                        {isModeEdition && (
                            <React.Fragment>
                                {isFirstTab && (
                                    <Button variant="secondary" type="button" customClass="padding-left" onClick={saveTree} disabled={isPublishing}>
                                        {isSavingData ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                            "Save Draft"
                                        )}
                                    </Button>
                                )}

                                <Button
                                    variant="secondary"
                                    type="button"
                                    customClass="padding-left"
                                    onClick={publishTree}
                                    disabled={isSavingData || isPublishing || !treeCode || disabledPublish}
                                >
                                    {isPublishing ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    ) : (
                                        "Publish"
                                    )}
                                </Button>
                            </React.Fragment>
                        )}
                        {!isModeEdition && isDraft && (
                            <Button variant="secondary" type="button" customClass="padding-left" onClick={() => history.push(`/edit/${treeCode}`)}>
                                Edit
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
