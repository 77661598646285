import { Tree } from "../../types/trees";
import * as _ from "lodash";

export class Validation {
    public static isValidTreeMainConfiguration(tree: Tree): boolean {
        if (_.isEmpty(tree.tree_code)) {
            throw new Error("Tree code empty.");
        }

        const regex1 = RegExp("^[A-Z0-9_-]+$", "g");
        const isValidTreeCode = regex1.test(tree.tree_code);
        if (!isValidTreeCode) {
            throw new Error("Validator: Tree code not valid.");
        }

        if (_.isEmpty(tree.description)) {
            throw new Error("Description empty.");
        }

        if (_.isEmpty(tree.schema)) {
            throw new Error("Schema not valid.");
        }

        return true;
    }
}
