import React, { useEffect, useState } from "react";
import { UserPreferencesService } from "../../services/user-preferences.service";
import { NodeItem } from "../../types/node";
import { Tree as TreeType } from "../../types/trees";
import { Loading } from "../../components/Loading/Loading";
import { NotFound } from "../../components/NotFound/NotFound";
import { useParams } from "react-router";
import { Header } from "../../components/Header/Header";
import { TreeEditorOptions } from "../../components/TreeEditorOptions/TreeEditorOptions";
import { TreeTab } from "../../components/TreeTab/TreeTab";
import { Constant } from "@common/types/lib/src/constants";
import * as _ from "lodash";
import { Validation } from "./Validations";
import { useHistory } from "react-router-dom";
import { Confirm } from "../../components/Confirm/Confirm";
import { Modal } from "@vacasa/react-components-lib";
import { ConfirmError } from "../../components/ConfirmError/ConfirmError";

interface TreeProps {}
export const Tree: React.FC<TreeProps> = (props) => {
    const params = useParams();
    const route = _.split(props?.["match"]?.path, "/");
    const DRAFT = Constant.treeType.DRAFT;
    const history = useHistory();

    //FLAGS
    const [isModeEdition, setIsModeEdition] = useState<boolean>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isPublishing, setIsPublishing] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [showValidTree, setShowValidTree] = useState<boolean>(false);
    const [savedLocalChangesNodes, setSavedLocalChangesNodes] = useState<boolean>(true);
    const [savedLocalChangesTree, setSavedLocalChangesTree] = useState<boolean>(true);

    //ERRORS
    const [error, setError] = useState<string>(null);
    const [error404, setError404] = useState<boolean>(null);
    const [showNotValidTree, setShowNotValidTree] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<string[]>(null);

    //DATA
    const [treeCode, setTreeCode] = useState<string>(null);
    const [externalServiceList, setExternalServiceList] = useState<string[]>([]);
    const [tree, setTree] = useState<any>({
        tree_code: "",
        description: "",
        schema: [
            {
                key: "name_variable",
                type: "boolean",
                allow_null: false //optional
                //description: "optional"
            }
        ],
        send_to_sf: false
    });

    //MODE
    useEffect((): void => {
        getTree();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //GET TREE
    useEffect((): void => {
        setIsModeEdition(route[1] === "edit" || route[1] === "new");
    }, [route]);

    const getTree = async (): Promise<void> => {
        try {
            if (params["code"]) {
                setIsLoading(true);
                setError(null);
                const treeInfo = await UserPreferencesService.getTree(params["code"], { "filter[status]": route[3] ?? DRAFT });
                setTreeCode(treeInfo.tree_code);
                updateNodes(treeInfo);
            }

            if (route[1] === "edit" || route[1] === "new") {
                const identifiers = await UserPreferencesService.getIdentifiers();
                setExternalServiceList(identifiers);
            }
        } catch (e) {
            const errorMessage = _.get(e.response, "data.[0].detail.error.message") ?? e.toString();
            setError(errorMessage);
            if (_.get(e.response, "data.[0].status") === 404) {
                setError404(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const transformTreeInObject = (nodes: { [key: string]: NodeItem }, root_id: string) => {
        const currentNode = nodes[root_id];
        const childrenList = [];
        for (const child of currentNode.children) {
            childrenList.push(transformTreeInObject(nodes, child.toString()));
        }
        return { ...currentNode, childrenList };
    };

    //SAVE TREE - main configuration
    const setStateAndGetMainConfiguration = () => {
        Validation.isValidTreeMainConfiguration(tree);
        setIsSaving(true);
        setIsSaved(false);
        setError404(false);
        setError(null);
        return { tree_code: tree.tree_code, description: tree.description, schema: tree.schema, send_to_sf: tree.send_to_sf };
    };
    const saveTree = async (): Promise<void> => {
        try {
            const body = setStateAndGetMainConfiguration();
            const savedTree = !treeCode ? await UserPreferencesService.saveNewDraftTree(body) : await UserPreferencesService.updateDraftTree(body);
            updateNodes(savedTree);
            setIsSaved(true);
            setSavedLocalChangesTree(true);
            setTreeCode(savedTree.tree_code);
        } catch (e) {
            const errorMessage = JSON.stringify(_.get(e.response, "data.[0]")) ?? e.toString();
            setError(errorMessage);
        } finally {
            setIsSaving(false);
        }
    };
    const updateNodes = async (treeInfo: Partial<TreeType>): Promise<void> => {
        setTree({ ...treeInfo, objectChildren: transformTreeInObject(treeInfo.nodes_list as any, treeInfo.root_id?.toString()) });
    };

    //PUBLISH TREE
    const setStateValidateTree = () => {
        setIsPublishing(true);
        setIsSaved(false);
        setError(null);
    };

    const validateTree = async (): Promise<void> => {
        try {
            setStateValidateTree();
            const validation = await UserPreferencesService.validateTree(treeCode);

            if (validation.isOk) {
                setShowValidTree(true);
            } else {
                setValidationErrors(validation.details);
                setShowNotValidTree(true);
            }
        } catch (e) {
            const errorMessage =
                `${_.get(e.response, "data.[0].detail.error.message")}, details: ${_.get(e.response, "data.[0].detail.error.details")}` ??
                e.toString();
            setError(errorMessage);
        } finally {
            setIsPublishing(false);
        }
    };

    const publishTree = async (): Promise<void> => {
        try {
            setShowValidTree(false);
            setIsPublishing(true);
            await UserPreferencesService.publishTree(treeCode);
            history.push(`/trees`);
        } catch (e) {
            const errorMessage =
                `${_.get(e.response, "data.[0].detail.error.message")}, details: ${_.get(e.response, "data.[0].detail.error.details")}` ??
                e.toString();
            setError(errorMessage);
        } finally {
            setIsPublishing(false);
        }
    };

    return (
        <div className="container-fluid font-custom">
            <div className="row">
                {isLoading ? (
                    <div className="col-sm-12">
                        <Loading className="padding" />
                    </div>
                ) : error404 ? (
                    <div className="col-sm-12">
                        <NotFound message={error} />
                    </div>
                ) : (
                    <div className="col-sm-12">
                        <Header>
                            <TreeEditorOptions
                                title={treeCode ?? "New Tree"}
                                isSavingData={isSaving}
                                isPublishing={isPublishing}
                                treeCode={treeCode}
                                isFirstTab={currentTab === 0}
                                isModeEdition={isModeEdition}
                                isDraft={route[3] === Constant.treeType.DRAFT}
                                saveTree={saveTree}
                                publishTree={validateTree}
                                disabledPublish={!savedLocalChangesNodes || !savedLocalChangesTree}
                            />
                        </Header>

                        <TreeTab
                            treeCode={treeCode}
                            tree={tree}
                            error={error}
                            treeSaved={isSaved}
                            setTree={setTree}
                            setCurrentTab={setCurrentTab}
                            isModeEdition={isModeEdition}
                            updateNodes={updateNodes}
                            setError={setError}
                            setIsSaved={setIsSaved}
                            externalServiceList={externalServiceList}
                            savedLocalChangesNodes={savedLocalChangesNodes}
                            setSavedLocalChangesNodes={setSavedLocalChangesNodes}
                            savedLocalChangesTree={savedLocalChangesTree}
                            setSavedLocalChangesTree={setSavedLocalChangesTree}
                        />
                    </div>
                )}
            </div>
            <Modal showModal={showNotValidTree} setShowModal={setShowNotValidTree} size="medium">
                {
                    <ConfirmError
                        title={"Invalid tree"}
                        message={`The tree "${treeCode}" has some validation problems:`}
                        errors={validationErrors}
                        showModal={showNotValidTree}
                        setShowModal={setShowNotValidTree}
                    />
                }
            </Modal>
            <Modal showModal={showValidTree} setShowModal={setShowValidTree} size="medium">
                {
                    <Confirm
                        title={"Publish tree confirmation"}
                        message={`The tree "${treeCode}" is going to be published. Are you sure?`}
                        showModal={showValidTree}
                        setShowModal={setShowValidTree}
                        confirmAction={publishTree}
                    />
                }
            </Modal>
        </div>
    );
};
