import React from "react";
import { Image, Button } from "@vacasa/react-components-lib";
import { useHistory } from "react-router-dom";
import "./NotFound.scss";

interface NotFoundProps {
    message: string;
}
export const NotFound: React.FC<NotFoundProps> = ({ message }) => {
    const history = useHistory();
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 not-found">
                    <h3>{message}</h3>
                    <Image.ImageUnplugged style={{ display: "flex", margin: "auto" }} width={500} />
                    <Button variant="primary" onClick={() => history.push("/trees")}>
                        Go Tree List
                    </Button>
                </div>
            </div>
        </div>
    );
};
