import axios from "axios";
import { Configuration } from "../Configuration";
import { StorageKeys } from "../types/constants";
import { Tree } from "../types/trees";
import { NodeItem } from "../types/node";
import {UserPreferencesTree} from "../types/userPreferencesTree";
import {PreferencesNode} from "../types/preferencesNode";

export class UserPreferencesService {
    private static readonly url: string = Configuration.getUPSURL();

    private static getHeaders = (): { "Content-Type": string; Authorization: string; "x-identity-token": string } => {
        const accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
        const idToken = localStorage.getItem(StorageKeys.ID_TOKEN);
        return {
            "Content-Type": "application/vnd.api+json",
            Authorization: `Bearer ${accessToken}`,
            "x-identity-token": idToken
        };
    };

    public static getTrees = async (params: { [key: string]: string }): Promise<Partial<Tree>[]> => {
        const headers = UserPreferencesService.getHeaders();
        const { data } = await axios.get(`${UserPreferencesService.url}/private/tree`, {
            headers,
            params
        });
        return data?.data.map(item => item.attributes);
    };

    public static getTree = async (treeCode: string, params: { [key: string]: string }): Promise<Partial<Tree>> => {
        const headers = UserPreferencesService.getHeaders();
        const { data } = await axios.get(`${UserPreferencesService.url}/private/tree/${treeCode}`, {
            headers,
            params
        });
        return data?.data.attributes;
    };

    public static saveNewDraftTree = async (tree: Partial<Tree>): Promise<Partial<Tree>> => {
        const headers = UserPreferencesService.getHeaders();
        const data = await axios.post(`${UserPreferencesService.url}/private/tree`, UserPreferencesService.formatBody("tree", tree), { headers });
        return data.data?.data?.attributes;
    };

    public static copyTreeToDraft = async (treeCode: string): Promise<Partial<Tree>> => {
        const headers = UserPreferencesService.getHeaders();
        const data = await axios.post(
            `${UserPreferencesService.url}/private/tree/${treeCode}/copy-to-draft`,
            UserPreferencesService.formatBody("tree", {}),
            {
                headers
            }
        );
        return data.data?.attributes;
    };

    public static deleteDraftTree = async (treeCode: string): Promise<void> => {
        const headers = UserPreferencesService.getHeaders();
        await axios.post(`${UserPreferencesService.url}/private/tree/${treeCode}/rollback`, UserPreferencesService.formatBody("tree", {}), {
            headers
        });
    };

    public static deleteTree = async (treeCode: string): Promise<void> => {
        const headers = UserPreferencesService.getHeaders();
        await axios.delete(`${UserPreferencesService.url}/private/tree/${treeCode}`, {
            headers
        });
    };

    public static updateDraftTree = async (tree: Partial<Tree>): Promise<Partial<Tree>> => {
        const headers = UserPreferencesService.getHeaders();
        const data = await axios.patch(
            `${UserPreferencesService.url}/private/tree/${tree.tree_code}`,
            UserPreferencesService.formatBody("tree", tree),
            { headers }
        );
        return data.data?.data?.attributes;
    };

    public static validateTree = async (treeCode: string): Promise<{ isOk: boolean; details?: string[] }> => {
        const headers = UserPreferencesService.getHeaders();
        try {
            await axios.get(`${UserPreferencesService.url}/private/tree/${treeCode}/validate`, {
                headers
            });
            return { isOk: true };
        } catch (e) {
            const details = e.response.data[0].detail.error.details;
            if (e.response?.data?.[0]?.status === 422) {
                return { isOk: false, details };
            }
            throw e;
        }
    };

    public static publishTree = async (treeCode: string): Promise<Partial<Tree>> => {
        const headers = UserPreferencesService.getHeaders();
        const data = await axios.post(`${UserPreferencesService.url}/private/tree/${treeCode}/save`, UserPreferencesService.formatBody("tree", {}), {
            headers
        });

        return data.data?.data?.attributes;
    };

    public static createNode = async (treeCode: string, type: string, node: Partial<NodeItem>): Promise<Partial<Tree>> => {
        const headers = UserPreferencesService.getHeaders();
        const data = await axios.post(
            `${UserPreferencesService.url}/private/tree/${treeCode}/${type}`,
            UserPreferencesService.formatBody(type, node),
            { headers }
        );
        return data.data?.data?.attributes;
    };

    public static updateNode = async (treeCode: string, type: string, node: Partial<NodeItem>): Promise<Partial<Tree>> => {
        const headers = UserPreferencesService.getHeaders();
        const data = await axios.patch(
            `${UserPreferencesService.url}/private/tree/${treeCode}/${type}/${node.id}`,
            UserPreferencesService.formatBody(type, node),
            { headers }
        );
        return data.data?.data?.attributes;
    };

    public static deleteNode = async (treeCode: string, nodeId: number): Promise<void> => {
        const headers = UserPreferencesService.getHeaders();
        await axios.delete(`${UserPreferencesService.url}/private/tree/${treeCode}/node/${nodeId}`, { headers });
    };

    public static getIdentifiers = async (): Promise<string[]> => {
        const headers = UserPreferencesService.getHeaders();
        const { data } = await axios.get(`${UserPreferencesService.url}/private/external-service/identifiers`, {
            headers
        });
        return data?.data.map(item => item.attributes.name);
    };

    public static getUserTreePreferences = async (treeCode: string, email: string): Promise<Partial<UserPreferencesTree>> => {
        const headers = UserPreferencesService.getHeaders();
        const { data } = await axios.get(`${UserPreferencesService.url}/private/preferences/${email}`, {
            headers,
            params: {
                'filter[tree]': treeCode
            }
        });
        const response = data?.data.attributes;
        const isOwnerData = data.included.filter(inc => inc.type =="isOwner");
        const idpUserId = data.included.filter(inc => inc.type =="idpUserId");
        response['isOwner'] = isOwnerData?.[0]?.value;
        response['idpUserId'] = idpUserId?.[0]?.value;
        return response;
    };

    public static saveUserPreference = async (idpUserId: string, treeCode: string, node: PreferencesNode): Promise<Partial<UserPreferencesTree>> => {
        const config = {
            headers: UserPreferencesService.getHeaders(),
            params: { 'filter[tree]': treeCode }
        };
        const body = UserPreferencesService.formatBody("user-preferences-tree", node);
        const { data } = await axios.put(`${UserPreferencesService.url}/private/preferences/${idpUserId}`, body, config);
        return data?.data.attributes;
    };

    public static formatBody(type: string, data: any): object {
        return {
            data: {
                type: type,
                attributes: data
            }
        };
    }
}
