import React, { Dispatch, SetStateAction, useState } from "react";
import { Button } from "@vacasa/react-components-lib";
import { UserPreferencesService } from "../../services/user-preferences.service";
import { Tree } from "../../types/trees";
import * as _ from "lodash";
import { CATEGORY, DRAFT } from "../../types/constants";
import "./SaveNodeButton.scss";
import { NodeItem } from "../../types/node";
import { SchemaProperty } from "../../types/schemaProperty";

interface DeleteNodeProps {
    nodeId?: number;
    tree: Tree;
    updateNodes: (tree: Tree) => void;
    setError: Dispatch<SetStateAction<string>>;
    setNodeSaved: Dispatch<SetStateAction<boolean>>;
    currentNode: NodeItem;
    schema: SchemaProperty[];
    setNodeId: Dispatch<SetStateAction<number>>;
    externalServices: Array<string>;
    setMessageOperation: Dispatch<SetStateAction<string>>;
    isModeEdition: boolean;
}
export const SaveNodeButton: React.FC<DeleteNodeProps> = ({
    tree,
    updateNodes,
    nodeId,
    setError,
    setNodeSaved,
    currentNode,
    schema,
    setNodeId,
    externalServices,
    setMessageOperation,
    isModeEdition
}) => {
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const setStateAndGetNodeToSave = () => {
        setMessageOperation("save");
        setIsSaving(true);
        setNodeSaved(false);
        setError(null);
        const data = currentNode?.type === CATEGORY ? { defaults: schema, external_service_ids: externalServices } : {};
        return { ...currentNode, ...data };
    };

    const SaveNode = async () => {
        try {
            const result = nodeId
                ? await UserPreferencesService.updateNode(tree.tree_code, currentNode.type, setStateAndGetNodeToSave())
                : await UserPreferencesService.createNode(tree.tree_code, currentNode.type, setStateAndGetNodeToSave());
            const updatedTree = await UserPreferencesService.getTree(tree.tree_code, { "filter[status]": DRAFT });
            updateNodes(updatedTree as any);
            setNodeSaved(true);
            setNodeId(result.id);
        } catch (e) {
            const errorMessage = _.get(e.response, "data.[0].detail.error.message") ?? e.toString();
            const errorDetails = JSON.stringify(_.get(e.response, "data.[0].detail.error"));
            setError(`${errorMessage}, ${errorDetails}`);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <React.Fragment>
            <Button variant="secondary" type="button" onClick={SaveNode} disabled={isSaving || !isModeEdition}>
                {isSaving ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Save"}
            </Button>
        </React.Fragment>
    );
};
