import React, { Dispatch, SetStateAction } from "react";
import { Icon, Button } from "@vacasa/react-components-lib";
import "./Confirm.scss";

interface ModalProps {
    title: string;
    message: string;
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    confirmAction: () => Promise<void>;
}
export const Confirm: React.FC<ModalProps> = ({ title, message, setShowModal, showModal, confirmAction }) => {
    return (
        <div>
            <div className="confirmation-title">
                <b>{title}</b>
            </div>
            <hr></hr>
            <div className="modal-body">
                <Icon.AlertTriangle height={50} width={50} className="modal-alert-icon" />
                <p>{message} </p>
                <div className="button-div">
                    <Button variant="secondary" onClick={() => confirmAction()}>
                        Confirm
                    </Button>
                    <Button variant="info" onClick={() => setShowModal(!showModal)}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};
