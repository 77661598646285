import React, { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import TreeView from "@mui/lab/TreeView";
import { Node } from "./Node";
import { Property } from "./Property";
import "./TreeNodes.scss";
import {CATEGORY, GROUP} from "../../types/constants";
import Chip from "@material-ui/core/Chip";
import {UserPreferencesTree} from "../../types/userPreferencesTree";

interface UserTreeNodeStructureProps {
    tree: UserPreferencesTree;
    isTreeEditable: boolean;
    addChange?: (node_id: string, key: string, value: any) => void;
    isSavingData: boolean;
}
export const UserTreeNodes: React.FC<UserTreeNodeStructureProps> = ({
    tree,
    isTreeEditable,
    addChange,
    isSavingData
}) => {
    const [expanded, setExpanded] = useState<string[]>(Object.keys(tree?.nodes) ?? []);

    useEffect((): void => {
        setExpanded(Object.keys(tree?.nodes));
    }, [tree?.nodes]);

    const recreateTree = (node): JSX.Element => {
        if (!node) {
            return;
        }
        const nodeType = node.children.length === 0 ? CATEGORY : GROUP;
        if (node.children.length === 0) {
            return (
                <Node
                    typeNode={nodeType}
                    nodeId={node.node_id}
                    title={node.display_name}
                    className="test"
                    key={`Node-${node.node_id}`}
                >
                    <div className="category-defaults">
                        <div className="container-fluid">
                            <div className="row">
                                {node.metadata.map((item) => (
                                    <div className="col-sm" style={{ textAlign: "center" }} key={`Node-${node.node_id}-${item.key}-${item.value}`}>
                                        <Property
                                            keyName={item.key}
                                            value={item.value}
                                            type={"boolean"}
                                            key={`property-${node.node_id}-${item.key}`}
                                            editable={item.value_by !== "readonly" && isTreeEditable && !isSavingData}
                                            onChange={(value) => { if(isTreeEditable && addChange) addChange(node.node_id, item.key, value) }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <hr style={{ color: "#bfc2c3" }} />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="justify-content-center">
                                        {node?.external_service_ids?.map((item) => (
                                            <Chip label={item} className="pill" key={`external-${node?.id}-${item}`} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Node>
            );
        }

        const childrenList = [];
        for (const child of node.childrenList) {
            childrenList.push(recreateTree(child));
        }

        return (
            <Node typeNode={nodeType} nodeId={node.node_id} title={node.display_name} key={`Node-${node.node_id}`}>
                {childrenList}
            </Node>
        );
    };

    //only expand if icon was clicked
    const handleToggle = (event: any, nodeIds: any): void => {
        event.persist();
        let iconClicked = event.target.closest(".MuiTreeItem-iconContainer");
        if (iconClicked) {
            setExpanded(nodeIds);
        }
    };

    return (
        <div className="container-tree">
            <div className="row">
                <div className="col-sm-12">
                    <TreeView
                        expanded={expanded}
                        onNodeToggle={handleToggle}
                        defaultCollapseIcon={<ArrowDropDownIcon style={{ fontSize: "25px" }} />}
                        defaultExpandIcon={<ArrowRightIcon style={{ fontSize: "25px" }} />}
                        sx={{ height: 650, flexGrow: 1, maxWidth: 900, overflowY: "auto", overflowX: "auto" }}
                    >
                        {recreateTree(tree?.objectChildren)}
                    </TreeView>
                </div>
            </div>
        </div>
    );
};
