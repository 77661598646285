import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./TreeEditor.scss";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { Icon } from "@vacasa/react-components-lib";
import { Tree } from "../../types/trees";
import * as _ from "lodash";
import Switch from "react-switch";

interface TreeEditorProps {
    isNew: boolean;
    isModeEdition: boolean;
    tree: Tree;
    setTree: Dispatch<SetStateAction<Tree>>;
    setSavedLocalChangesTree: Dispatch<SetStateAction<boolean>>;
}
export const TreeEditor: React.FC<TreeEditorProps> = ({ tree, isNew, setTree, isModeEdition, setSavedLocalChangesTree }) => {
    const [isTreeCodeValid, setIsTreeCodeValid] = useState<boolean>(true);
    const [characterCounter, setCharacterCounter] = useState<number>(tree?.description?.length);
    const [originalTree] = useState<Tree>(tree);

    const schemaUpdate = (schema: any) => {
        setTree({ ...tree, schema: schema });
    };

    useEffect((): void => {
        const regex1 = RegExp("^[A-Z0-9_-]+$", "g");
        const isValid = regex1.test(tree.tree_code);
        setIsTreeCodeValid(isValid);
    }, [tree?.tree_code]);

    useEffect((): void => {
        if (
            !_.isEqual(tree.tree_code, originalTree.tree_code) ||
            !_.isEqual(tree.description, originalTree.description) ||
            !_.isEqual(tree.schema, originalTree.schema)
        ) {
            setSavedLocalChangesTree(false);
        } else {
            setSavedLocalChangesTree(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tree?.tree_code, tree?.schema, tree?.description]);

    return (
        <div className="tree-editor">
            <div className="row ">
                <div className="col-lg-6">
                    <div className="container-form ">
                        <div className="mb-3 row">
                            <label className="col-lg-3 col-form-label">Tree Code:</label>
                            <div className="col-lg-9">
                                <input
                                    type="text"
                                    className={isTreeCodeValid ? "form-control" : "form-control is-invalid"}
                                    disabled={!isNew || !isModeEdition}
                                    onChange={(value) => {
                                        setTree({ ...tree, tree_code: value.target.value });
                                    }}
                                    value={tree?.tree_code}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-lg-3 col-form-label">Description:</label>
                            <div className="col-lg-9">
                                <textarea
                                    className="form-control shadow-none"
                                    rows={7}
                                    disabled={!isModeEdition}
                                    onChange={(value) => {
                                        setTree({ ...tree, description: value.target.value });
                                        setCharacterCounter(value.target.value?.length);
                                    }}
                                    value={tree?.description}
                                    maxLength={200}
                                />
                                {isModeEdition && <div className="character-counter">{characterCounter}/200</div>}
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-lg-3 col-form-label">Send to SFMC:</label>
                            <div className="col-lg-9">
                                <Switch
                                    onChange={(event) => {setTree({ ...tree, send_to_sf: event});}}
                                    checked={tree.send_to_sf}
                                    disabled={!isModeEdition}
                                    onColor="#6BA342"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {isModeEdition && (
                    <div className="col-lg-6 ">
                        <div className="container-form info-paragraph ">
                            <Icon.AlertCircle height={20} width={20} /> <b> Considerations</b>
                            <li>
                                The tree code must be capitalized, without spaces or special symbols, you can use <b>-</b> or <b>_</b>, Exs: ABC-DEF,
                                ABC_DEF.
                            </li>
                            <li>
                                The schema must be an array of objects, it must necessarily contain the variable key of type string, which represents
                                the name, and the variable type of type string, which represents the type (string, boolean, number, array).
                            </li>
                            <li>The variable allow_read_only is optional, and represents whether to save the value of the variable key as null.</li>
                        </div>
                    </div>
                )}

                <div className="col-lg-12">
                    <div className="container-form editor">
                        Tree Schema:
                        <JSONInput
                            id="schema-editor"
                            placeholder={tree?.schema}
                            locale={locale}
                            theme="light_mitsuketa_tribute"
                            colors={{
                                default: "black",
                                background: "white",
                                string: "red"
                            }}
                            viewOnly={!isModeEdition}
                            onBlur={(args) => schemaUpdate(args.jsObject)}
                            height="auto"
                            width="auto"
                            style={{
                                body: {
                                    overflowX: "auto",
                                    border: "solid 1px #ced4da",
                                    borderRadius: "0.25rem",
                                    minHeight: "360px"
                                },
                                contentBox: {
                                    flex: "0 0 auto"
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
