import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Input } from "@vacasa/react-components-lib";
import "./FloatingPanel.scss";
import { Property } from "../TreeNodes/Property";
import { Tree } from "../../types/trees";
import * as _ from "lodash";
import { FloatingPanelHeader } from "./FloatingPanelHeader";
import { FloatingPanelFooter } from "./FloatingPanelFooter";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { SchemaProperty } from "../../types/schemaProperty";
import { CATEGORY, GROUP, ROOT, SELECT_STYLE, TEXTFIELD_STYLE } from "../../types/constants";
import { FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

interface ModalProps {
    tree: Tree;
    nodeId?: number;
    updateNodes: (tree: Tree) => void;
    openNew: boolean;
    setError: Dispatch<SetStateAction<string>>;
    setNodeSaved: Dispatch<SetStateAction<boolean>>;
    setOpenNew: Dispatch<SetStateAction<boolean>>;
    setNodeId: Dispatch<SetStateAction<number>>;
    externalServiceList: Array<string>;
    setMessageOperation: Dispatch<SetStateAction<string>>;
    setSavedLocalChanges: Dispatch<SetStateAction<boolean>>;
    isModeEdition: boolean;
}
export const FloatingPanel: React.FC<ModalProps> = ({
    tree,
    nodeId,
    updateNodes,
    setError,
    setNodeSaved,
    setOpenNew,
    setNodeId,
    openNew,
    externalServiceList,
    setMessageOperation,
    setSavedLocalChanges,
    isModeEdition
}) => {
    const [schema, setSchema] = useState<SchemaProperty[]>([]);
    const [externalServices, setExternalServices] = useState<Array<string>>([]);
    const [currentNode, setCurrentNode] = useState<any>(
        nodeId
            ? tree.nodes_list[nodeId]
            : {
                  display_name: "",
                  description: "",
                  type: GROUP,
                  parent: null
              }
    );

    useEffect(() => {
        const updateNode = () => {
            if (nodeId) {
                setOpenNew(false);
                setCurrentNode(tree.nodes_list[nodeId]);
                setSchema(
                    tree.schema.map((schemaAttribute) => {
                        const selectedValue = tree.nodes_list[nodeId]?.defaults?.find((def) => def.key === schemaAttribute.key);
                        return {
                            key: schemaAttribute.key,
                            type: schemaAttribute.type,
                            allow_null: schemaAttribute.allow_null,
                            description: schemaAttribute.description,
                            value: selectedValue?.value ?? false,
                            read_only: selectedValue?.read_only ?? false
                        } as SchemaProperty;
                    }) ?? []
                );
                setExternalServices(tree.nodes_list[nodeId]?.external_service_ids);
            }
        };

        updateNode();
    }, [nodeId, tree.nodes_list, tree.schema, setOpenNew]);

    useEffect(() => {
        const updateNode = () => {
            if (openNew) {
                setCurrentNode({
                    display_name: "",
                    description: "",
                    type: GROUP,
                    parent: null
                });
                setSchema(
                    tree.schema.map((schemaAttribute) => {
                        return {
                            key: schemaAttribute.key,
                            type: schemaAttribute.type,
                            allow_null: schemaAttribute.allow_null,
                            description: schemaAttribute.description,
                            value: false,
                            read_only: false
                        };
                    }) ?? []
                );
                setExternalServices([]);
            }
        };

        updateNode();
    }, [openNew, tree.nodes_list, tree.schema]);

    useEffect(() => {
        setSavedLocalChanges(true);
        if (nodeId) {
            if (!_.isEqual(currentNode, tree.nodes_list[nodeId])) {
                setSavedLocalChanges(false);
            }
            if (currentNode?.type === CATEGORY) {
                if (
                    !_.isEqual(
                        schema.map((item) => ({ key: item.key, value: item.value, read_only: item.read_only })),
                        tree.schema.map((item) => {
                            const selectedValue = tree.nodes_list[nodeId]?.defaults?.find((def) => def.key === item.key);
                            return {
                                key: selectedValue?.key,
                                value: selectedValue?.value,
                                read_only: !_.isUndefined(selectedValue?.read_only) ? selectedValue?.read_only : false
                            };
                        })
                    )
                ) {
                    setSavedLocalChanges(false);
                }
            }
        }
    }, [currentNode, schema, nodeId, tree.nodes_list, tree.schema, setSavedLocalChanges]);

    useEffect(() => {
        if (
            nodeId &&
            currentNode?.type === CATEGORY &&
            tree.nodes_list[nodeId]?.external_service_ids?.length > 0 &&
            !_.isEqual(_.sortBy(externalServices), _.sortBy(tree.nodes_list[nodeId]?.external_service_ids))
        ) {
            setSavedLocalChanges(false);
        }
    }, [externalServices, nodeId, tree.nodes_list, setSavedLocalChanges, currentNode?.type]);

    const getParentList = (): { value: number; key: number; display: string }[] => {
        if (tree.nodes_list[nodeId]?.type === ROOT) {
            return [{ value: null, key: null, display: "" }];
        }
        const filteredList = _.toArray(tree.nodes_list).filter((node) => node.id !== nodeId && [ROOT, GROUP].includes(node.type));
        return filteredList.map((node) => ({ value: node.id, key: node.id, display: node.display_name }));
    };

    const onChangeSchema = (value: any, key: any): void => {
        setSchema(schema.map((item) => (item.key === key ? { ...item, value } : item)));
    };

    const onChangeReadOnly = (value: any, key: any): void => {
        setSchema(schema.map((item) => (item.key === key ? { ...item, read_only: value } : item)));
    };

    const newChange = (value: any, type: string): void => {
        setCurrentNode({ ...currentNode, [type]: value });
    };

    const onChangeExternalServices = (event: any, value: any): void => {
        setExternalServices(value);
    };

    return (
        <div className="floating-panel">
            <FloatingPanelHeader nodeId={nodeId} setOpenNew={setOpenNew} setNodeId={setNodeId} setSavedLocalChanges={setSavedLocalChanges} />
            <hr />
            <div className="modal-body modal-body-custom hidden-scroll">
                <div className="row">
                    <div className="col-6">
                        <div className="row">
                            <p className="col-4">Parent:</p>
                            <FormControl className="col-6">
                                <Select
                                    value={currentNode?.parent ?? ""}
                                    onChange={(e) => newChange(e.target.value, "parent")}
                                    disabled={currentNode?.type === ROOT || !isModeEdition}
                                    size="small"
                                    sx={SELECT_STYLE}
                                >
                                    {getParentList().map((item) => (
                                        <MenuItem value={item.value} key={`option-${item.value}`}>
                                            {item.display}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="row">
                            <p className="col-4">Node Name:</p>
                            <Input
                                type="text"
                                customClass="col-8"
                                value={currentNode?.display_name}
                                onChange={(e) => newChange(e.target.value, "display_name")}
                                disabled={!isModeEdition}
                                placeholder="Node name"
                            />
                        </div>
                    </div>
                </div>
                <div className="row property-row">
                    <div className="col-3">
                        <p className="radio-group-title">Type:</p>
                    </div>
                    <div className="col-9">
                        <RadioGroup
                            row
                            onChange={(e) => newChange(e.target.value, "type")}
                            value={currentNode?.type === ROOT ? GROUP : currentNode?.type}
                        >
                            <FormControlLabel
                                value={GROUP}
                                control={<Radio disabled={nodeId !== null || !isModeEdition} className="tree-checkbox-radio" />}
                                label={GROUP}
                                className="col-5"
                            />
                            <FormControlLabel
                                value={CATEGORY}
                                control={<Radio disabled={nodeId !== null || !isModeEdition} className="tree-checkbox-radio" />}
                                label={CATEGORY}
                                className="col-5"
                            />
                        </RadioGroup>
                    </div>
                </div>
                <div className="row property-row">
                    <p className="col-12">Description:</p>
                    <div className="col-12">
                        <textarea
                            value={currentNode?.description}
                            rows={2}
                            onChange={(e) => newChange(e.target.value, "description")}
                            disabled={!isModeEdition}
                            placeholder="Node description"
                        />
                    </div>
                </div>
                {currentNode?.type === CATEGORY && (
                    <div>
                        <div className="row property-row">
                            <p>Default Values:</p>
                        </div>
                        <div style={{ paddingRight: "12px" }}>
                            <div className="row table-container">
                                <table className="col-12">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "center" }}>Items ({tree.schema.length})</th>
                                            <th style={{ textAlign: "center" }}>Default</th>
                                            <th style={{ textAlign: "center" }}>Read Only</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {schema?.map((item) => (
                                            <tr className="col-sm" style={{ textAlign: "center" }} key={`prop-${item.key}`}>
                                                <th>{item.key}</th>
                                                <th style={{ paddingTop: "8px" }}>
                                                    <Property
                                                        keyName=""
                                                        value={item.value}
                                                        type={item.type}
                                                        key={`property-${currentNode.id}-${item.key}`}
                                                        editable={isModeEdition}
                                                        onChange={(value) => onChangeSchema(value, item.key)}
                                                    />
                                                </th>
                                                <th>
                                                    <Checkbox
                                                        checked={item.read_only}
                                                        onChange={(e) => {
                                                            onChangeReadOnly(e.target.checked, item.key);
                                                        }}
                                                        disabled={!isModeEdition}
                                                        className="tree-checkbox-radio"
                                                    />
                                                </th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row property-row">
                            <p>External Services:</p>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Stack spacing={3}>
                                    <Autocomplete
                                        key={`autocomplete-es-${currentNode?.id ?? ""}`}
                                        multiple
                                        filterSelectedOptions
                                        id={`tag-es-${currentNode?.id ?? ""}`}
                                        options={externalServiceList}
                                        getOptionLabel={(option) => option}
                                        value={externalServices ?? []}
                                        onChange={onChangeExternalServices}
                                        renderInput={(params) => <TextField {...params} sx={TEXTFIELD_STYLE} />}
                                        disabled={!isModeEdition}
                                    />
                                </Stack>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <hr />
            <FloatingPanelFooter
                tree={tree}
                nodeId={nodeId}
                setNodeId={setNodeId}
                updateNodes={updateNodes}
                setError={setError}
                setNodeSaved={setNodeSaved}
                currentNode={currentNode}
                schema={schema}
                externalServices={externalServices}
                setMessageOperation={setMessageOperation}
                isModeEdition={isModeEdition}
            />
        </div>
    );
};
