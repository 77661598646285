import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import "./TreeNodes.scss";

interface propertyProps {
    value: any;
    type: string;
    keyName?: string;
    editable?: boolean;
    onChange?: (value: boolean) => void;
}

export const Property: React.FC<propertyProps> = ({ keyName, value, type, editable = false, onChange }) => {
    const [switchValue, setSwitchValue] = useState(value);

    useEffect(() => {
        setSwitchValue(value);
    }, [value]);

    return (
        <React.Fragment>
            {type === "boolean" && (
                <label>
                    {keyName && (
                        <React.Fragment>
                            <span>{keyName}</span>
                            <br />
                        </React.Fragment>
                    )}
                    <Switch
                        onChange={(event) => {
                            onChange(event);
                            setSwitchValue(event);
                        }}
                        checked={switchValue}
                        disabled={!editable}
                        onColor="#6BA342"
                    />
                </label>
            )}
        </React.Fragment>
    );
};
