import React, { useEffect, useState } from "react";
import { UserPreferencesService } from "../../services/user-preferences.service";
import { Tree } from "../../types/trees";
import { Header } from "../../components/Header/Header";
import { Page } from "../../components/Page/Page";
import { TreesOptions } from "../../components/TreesOptions/TreesOptions";
import { Table } from "../../components/Table/Table";
import { Loading } from "../../components/Loading/Loading";
import { Confirm } from "../../components/Confirm/Confirm";
import { useHistory } from "react-router-dom";
import { DRAFT, LIVE } from "../../types/constants";
import { Modal } from "@vacasa/react-components-lib";
import moment from "moment";
import * as _ from "lodash";
import {LoggingService} from "../../services/logging.service";

interface TreesProps {}
export const Trees: React.FC<TreesProps> = (props) => {
    const history = useHistory();
    if(!LoggingService.canReadCategories()) {
        if(LoggingService.canReadUserPreferences()) {
            history.push("/user-trees");
        }
        else {
            throw new Error("You don't have enough permissions to view this page.")
        }
    }

    //FLAGS
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showClnModal, setShowClnModal] = useState<boolean>(false);
    const [cloneTreeData, setCloneTreeData] = useState<string>("");
    const [showDelModal, setShowDelModal] = useState<boolean>(false);
    const [deleteTreeData, setDeleteTreeData] = useState<string[]>([]);

    //DATA
    const [treeList, setTreeList] = useState([]);

    useEffect(() => {
        loadTrees();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadTrees = async () => {
        if(!LoggingService.canReadCategories()) {
            return;
        }
        try {
            setIsLoading(true);
            let trees = await UserPreferencesService.getTrees({
                "filter[status]": "draft,live"
            });

            trees = trees.map((item) => {
                const isLive = item.status === LIVE;
                const hasDraft = isLive ? hasDraftTreeLive(item.tree_code, trees) : false;
                return {
                    ...item,
                    isLive: isLive,
                    hasDraft: hasDraft,
                    tooltipEdit: isLive ? (hasDraft ? "Draft already exists" : "Copy to draft") : "Edit draft",
                    classNameEdit: isLive && hasDraft ? "disabled-icon" : "",
                    updated_at: moment(item.updated_at).format("MM/DD/YYYY hh:mm") as any,
                    status: _.capitalize(item.status)
                };
            });

            setTreeList(_.orderBy(trees, ["tree_code"]));
            setIsLoading(false);
        } catch (e) {
            alert(e);
            setIsLoading(false);
        }
    };

    const hasDraftTreeLive = (treeCode: string, treeList: Partial<Tree>[]) => {
        return !!treeList.find((item) => item.tree_code === treeCode && item.status === DRAFT);
    };

    const showCloneConfirmationModal = (treeCode: string) => {
        setCloneTreeData(treeCode);
        setShowClnModal(true);
    };

    const cloneTree = async () => {
        setIsLoading(true);
        try {
            await UserPreferencesService.copyTreeToDraft(cloneTreeData);
        } catch (e) {
            alert(`Failed cloning tree: ${e.message}`);
        } finally {
            setShowClnModal(false);
            history.push(`/edit/${cloneTreeData}`);
        }
    };

    const showDeleteConfirmationModal = (treeCode: string, status: string) => {
        setDeleteTreeData([treeCode, status]);
        setShowDelModal(true);
    };

    const deleteTree = async () => {
        setIsLoading(true);
        try {
            if (deleteTreeData[1] === "Live") {
                await UserPreferencesService.deleteTree(deleteTreeData[0]);
            } else {
                await UserPreferencesService.deleteDraftTree(deleteTreeData[0]);
            }
        } catch (e) {
            alert(`Failed deleting tree: ${e.message}`);
        } finally {
            setShowDelModal(false);
            await loadTrees();
        }
    };

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    {isLoading ? (
                        <div className="col-sm-12">
                            <Loading className="padding" />
                        </div>
                    ) : (
                        <div className="col-sm-12">
                            <Header>
                                <TreesOptions />
                            </Header>
                            <Page title="Tree List">
                                <Table data={treeList} cloneTree={showCloneConfirmationModal} deleteTree={showDeleteConfirmationModal} />
                                <Modal showModal={showClnModal} setShowModal={setShowClnModal} size="medium">
                                    {
                                        <Confirm
                                            title={"Cloning confirmation"}
                                            message={`Are you sure you want to clone ${cloneTreeData} into a draft?`}
                                            showModal={showClnModal}
                                            setShowModal={setShowClnModal}
                                            confirmAction={cloneTree}
                                        />
                                    }
                                </Modal>
                                <Modal showModal={showDelModal} setShowModal={setShowDelModal} size="medium">
                                    {
                                        <Confirm
                                            title={"Delete confirmation"}
                                            message={`Are you sure you want to delete ${deleteTreeData[0]} (${deleteTreeData[1]} tree)?`}
                                            showModal={showDelModal}
                                            setShowModal={setShowDelModal}
                                            confirmAction={deleteTree}
                                        />
                                    }
                                </Modal>
                            </Page>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
