import React, { Dispatch, SetStateAction } from "react";
import { Icon } from "@vacasa/react-components-lib";
import "./FloatingPanel.scss";

interface FloatingPanelHeaderProps {
    nodeId?: number;
    setSavedLocalChanges: Dispatch<SetStateAction<boolean>>;
    setOpenNew: Dispatch<SetStateAction<boolean>>;
    setNodeId: Dispatch<SetStateAction<number>>;
}
export const FloatingPanelHeader: React.FC<FloatingPanelHeaderProps> = ({ nodeId, setOpenNew, setNodeId, setSavedLocalChanges }) => {
    return (
        <div className="modal-title">
            <div className="container">
                <div className="row">
                    <div className="col-sm-1">
                        <Icon.XCircleInverse
                            height={20}
                            width={20}
                            className="close-icon"
                            onClick={() => {
                                setOpenNew(false);
                                setNodeId(null);
                                setSavedLocalChanges(true);
                            }}
                        />
                    </div>
                    <div className="col-sm-10 main-title">
                        <b className="">{nodeId ? "Update Node" : "New Node"}</b>
                    </div>
                </div>
            </div>
        </div>
    );
};
