import {ALL_SCOPES} from "./types/auth";

export class Configuration {
    static getUPSURL = (): string => {
        return process.env.REACT_APP_UPS_API_URL;
    };

    static getNSURL = (): string => {
        return process.env.REACT_APP_NS_API_URL;
    };

    static getIDPConfiguration = () => {
        const { REACT_APP_IDP_CLIENT_ID, REACT_APP_IDP_AUDIENCE, REACT_APP_IDP_REDIRECT_URI, REACT_APP_CUSTOM_NODE_ENV } = process.env;

        const envs = {
            production: "prod",
            staging: "stage",
            local: "stage"
        };

        return {
            clientId: REACT_APP_IDP_CLIENT_ID,
            scopes: ALL_SCOPES.join(" "),
            audience: REACT_APP_IDP_AUDIENCE,
            redirectUrl: REACT_APP_IDP_REDIRECT_URI,
            directoryHint: "onelogin",
            env: envs[REACT_APP_CUSTOM_NODE_ENV]
        };
    };
}
