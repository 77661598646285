import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Tree } from "../../types/trees";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import TreeView from "@mui/lab/TreeView";
import { Node } from "./Node";
import { Property } from "./Property";
import "./TreeNodes.scss";
import * as _ from "lodash";
import { Icon } from "@vacasa/react-components-lib";
import { CATEGORY } from "../../types/constants";
import Chip from "@material-ui/core/Chip";

interface TreeNodeStructureProps {
    tree: Tree;
    setNode: Dispatch<SetStateAction<number>>;
    savedLocalChanges: boolean;
}
export const TreeNodes: React.FC<TreeNodeStructureProps> = ({ tree, setNode, savedLocalChanges }) => {
    const [expanded, setExpanded] = useState<string[]>(Object.keys(tree?.nodes_list));
    const [selected, setSelected] = useState<string[]>([]);

    useEffect((): void => {
        setExpanded(Object.keys(tree?.nodes_list));
    }, [tree?.nodes_list]);

    const getValue = (key: string, defaults: Array<any>): any => {
        return defaults.find((item) => item.key === key)?.value;
    };

    const searchOrphanNodes = (defaults: Array<any>): any => {
        return _.differenceBy(defaults, tree.schema, "key").map((item) => ({ ...item, isOrphan: true }));
    };

    const recreateTree = (node): JSX.Element => {
        if (!node) {
            return;
        }
        if (node.type === CATEGORY) {
            let schemaLocal = [
                ...tree.schema.map((item) => {
                    const value = getValue(item.key, node.defaults);
                    return { ...item, value, isOrphan: _.isUndefined(value) };
                }),
                ...searchOrphanNodes(node.defaults)
            ];

            const nodeWithOrphanNodes = schemaLocal.find((item) => item.isOrphan);

            return (
                <Node
                    typeNode={node.type}
                    nodeId={node.id.toString()}
                    title={node.display_name}
                    className="test"
                    key={`Node-${node.id}`}
                    corruptCategory={nodeWithOrphanNodes}
                >
                    <div className="category-defaults">
                        <div className="container-fluid">
                            <div className="row">
                                {schemaLocal.map((item) => (
                                    <div className="col-sm" style={{ textAlign: "center" }} key={`Node-${node.id}-${item.key}-${item.value}`}>
                                        {!item.isOrphan ? (
                                            <Property
                                                keyName={item.key}
                                                value={item.value}
                                                type={item.type}
                                                key={`property-${node.id}-${item.key}`}
                                            />
                                        ) : (
                                            <label>
                                                <span style={{ color: "" }}>{item.key}</span>
                                                <br />
                                                <Icon.AlertOctagon height={20} width={20} />
                                            </label>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <hr style={{ color: "#bfc2c3" }} />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="justify-content-center">
                                        {node?.external_service_ids?.map((item) => (
                                            <Chip label={item} className="pill" key={`external-${node?.id}-${item}`} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Node>
            );
        }

        const childrenList = [];
        for (const child of node.childrenList) {
            childrenList.push(recreateTree(child));
        }

        return (
            <Node typeNode={node.type} nodeId={node.id.toString()} title={node.display_name} key={`Node-${node.id}`}>
                {childrenList}
            </Node>
        );
    };

    //only expand if icon was clicked
    const handleToggle = (event: any, nodeIds: any): void => {
        event.persist();
        let iconClicked = event.target.closest(".MuiTreeItem-iconContainer");
        if (iconClicked) {
            setExpanded(nodeIds);
        }
    };

    //only select if icon wasn't clicked
    const handleSelect = (event: any, nodeId: any): void => {
        event.persist();
        let iconClicked = event.target.closest(".MuiTreeItem-iconContainer");
        if (!iconClicked && savedLocalChanges) {
            setSelected(nodeId);
            setNode(nodeId);
        }
    };

    return (
        <div className="container-tree">
            <div className="row">
                <div className="col-sm-12">
                    <TreeView
                        expanded={expanded}
                        selected={selected}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}
                        defaultCollapseIcon={<ArrowDropDownIcon style={{ fontSize: "25px" }} />}
                        defaultExpandIcon={<ArrowRightIcon style={{ fontSize: "25px" }} />}
                        sx={{ height: 650, flexGrow: 1, maxWidth: 900, overflowY: "auto", overflowX: "auto" }}
                    >
                        {recreateTree(tree?.objectChildren)}
                    </TreeView>
                </div>
            </div>
        </div>
    );
};
