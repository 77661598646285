import {StorageKeys} from "../types/constants";
import {AccessToken, IdentityToken, SCOPES} from "../types/auth";
import decode from "jwt-decode";

export class LoggingService {
    static getUserName(): string {
        const identityToken = localStorage.getItem(StorageKeys.ID_TOKEN);

        if (identityToken) {
            const decodedUser = decode(identityToken) as IdentityToken;
            return decodedUser.name;
        }

        return null;
    }

    static canReadCategories(): boolean {
        return this.getScopeFromToken(SCOPES.CATEGORIES_READ);
    }

    static canReadUserPreferences(): boolean {
        return this.getScopeFromToken(SCOPES.PREFERENCES_READ)
    }

    static canWriteUserPreferences(): boolean {
        return this.getScopeFromToken(SCOPES.PREFERENCES_WRITE)
    }

    private static getScopeFromToken(scope: string): boolean {
        const accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);

        if (accessToken) {
            const decodedToken = decode(accessToken) as AccessToken;
            return decodedToken.scopes.includes(scope);
        }

        return false;
    }
}