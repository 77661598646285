import React, { ReactNode } from "react";
import { IconButton } from '@mui/material';
import { Icon } from "@vacasa/react-components-lib";
import "./Page.scss";

interface PageProps {
    title?: string;
    children: ReactNode;
    refreshAction?: () => Promise<void>;
}
export const Page: React.FC<PageProps> = (
    {
        children,
        title ,
        refreshAction
    }
) => {
    return (
        <div className="container-fluid without-padding border flex-fill">
            <div className="row without-padding">
                {title &&
                    <div className="col-sm-12">
                        <div className="sub-header">
                            {title}
                            { refreshAction &&
                                <div className={"float-right"}>
                                    <IconButton aria-label="Refresh tree data" size="small">
                                        <Icon.RefreshCW height={20} width={20} onClick={refreshAction}></Icon.RefreshCW>
                                    </IconButton>
                                </div>

                            }
                        </div>

                    </div>}
                <div className="col-sm-12">{children}</div>
            </div>
        </div>
    );
};
