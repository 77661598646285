import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Configuration } from "./Configuration";
import { Container } from "@material-ui/core";
import { ImplicitFlow, ThemeProvider } from "@vacasa/react-components-lib";
import { Trees } from "./views/Trees/Trees";
import { Navbar } from "./components/Navbar/Navbar";
import { Tree } from "./views/Tree/Tree";
import { UserTree } from "./views/UserTree/UserTree";
import theme from "@vacasa/react-components-lib/lib/themes/default";
import "./App.scss";

function App() {
    return (
        <ImplicitFlow {...Configuration.getIDPConfiguration()}>
            <BrowserRouter>
                <Navbar title="UPS" version="v1.0" />
                <ThemeProvider theme={theme}>
                    <Container maxWidth="xl">
                        <Switch>
                            <Route exact path="/trees" component={Trees} />
                            <Route exact path="/new" component={Tree} />
                            <Route exact path="/edit/:code" component={Tree} />
                            <Route exact path="/view/:code/draft" component={Tree} />
                            <Route exact path="/view/:code/live" component={Tree} />
                            <Route exact path="/user-trees" component={UserTree} />
                            <Route exact path="/" component={Trees} />
                        </Switch>
                    </Container>
                </ThemeProvider>
            </BrowserRouter>
        </ImplicitFlow>
    );
}

export default App;
