import React from "react";
import "./Loading.scss";

interface LoadingProps {
    className?: string;
}
export const Loading: React.FC<LoadingProps> = ({ className }) => {
    return (
        <div id="loading" className={`loading ${className ?? ""}`}>
            <div className={"loader-dots"}>
                <div className={"bounce1"}></div>
                <div className={"bounce2"}></div>
                <div className={"bounce3"}></div>
            </div>
        </div>
    );
};
